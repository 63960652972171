import React, { useEffect, useState } from 'react';
import { BrowserRouter as Router } from 'react-router-dom';
import { shallowEqual } from 'react-redux';
import { QueryClientProvider } from '@tanstack/react-query';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import { app } from './config';
import { getUserFromIds } from './store/user/user-actions';
import { setLive } from './store/live/live-actions';
import useStatus from './hooks/use-status';
import memoNoProps from './utils/memo-no-props';
import PublicRoutes from './routes/PublicRoutes';
import PrivateRoutes from './routes/PrivateRoutes';
import Layout from './layout';
import useIsSignedIn, { sessionToUser } from './hooks/use-is-signed-in';
import { currentAuthSession } from './auth';
import SideBar from './components/side-bar';
import useDashboardVersion from './hooks/use-dashboard-version';
import { queryClient } from './react-query';
import { useAppDispatch, useAppSelector } from './store/hooks';

export const App = () => {
  useDashboardVersion();
  const statusContextHolder = useStatus();
  const dispatch = useAppDispatch();
  const [bootstrapped, setBootstrapped] = useState(false);
  const { fullySignedIn, isLiveAccount, userEmail, invoiceEmails } =
    useAppSelector(
      state => ({
        fullySignedIn: !!state.user.details && !!state.account.details,
        isLiveAccount: !!state.account.details?.liveActive,
        userEmail: state.user.details?.email,
        invoiceEmails:
          state.account.billingConfigurations?.entity?.invoiceEmails,
      }),
      shallowEqual,
    );

  const { signIn, signedIn, user, isModerator, isNewSession } = useIsSignedIn();

  useEffect(() => {
    if (bootstrapped) return;

    currentAuthSession().then(session => {
      if (session) {
        signIn(sessionToUser(session), false);
      }
      setBootstrapped(true);
    });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (user && !isModerator) {
      dispatch(getUserFromIds(user.userId, user.accountId));
    }
  }, [invoiceEmails, userEmail, dispatch, isLiveAccount, isModerator, user]);

  useEffect(() => {
    if (!isModerator && isNewSession && fullySignedIn && isLiveAccount) {
      dispatch(setLive(true));
    }
  }, [dispatch, fullySignedIn, isLiveAccount, isModerator, isNewSession]);

  useEffect(() => {
    document.body.setAttribute('data-fidel-env', app.ENV);
    document.body.setAttribute('data-fidel-version', app.VERSION);
  }, []);

  if (!bootstrapped) {
    return null;
  }

  if (signedIn && !fullySignedIn && !isModerator) {
    return null;
  }

  return (
    <Router>
      <QueryClientProvider client={queryClient}>
        <ReactQueryDevtools initialIsOpen={false} />
        {signedIn ? (
          <>
            <Layout side={<SideBar />} main={<PrivateRoutes />} />
          </>
        ) : (
          <PublicRoutes />
        )}
        {statusContextHolder}
      </QueryClientProvider>
    </Router>
  );
};

export default memoNoProps(App);
