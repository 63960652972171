import AccountApiService, {
  AddVirtualCardPayload,
  SendGoLiveRequestPayload,
  ToggleAccountLockPayload,
  UpdateAccountPayload,
  UpdateAccountSecurityPayload,
} from './account-api-service';
import { actions as statusActions } from '../status/status-reducer';
import { api } from '../../config';
import { getDataItem } from '../utils/transform';
import http from '../../services/http-service';
import { actions } from './account-reducer';
import {
  BillingConfigurations,
  VirtualCard,
  AccountDetails,
  AccountImages,
  AccountProfile,
  Invoice,
  PricingTemplate,
  AccountNotification,
} from './account-model';
import { Thunk } from '../types';
import { State } from '..';

const accountApiService = new AccountApiService();

const getAccountId = (state: State) => state.account.details?.id;
const selectLiveAgreement = (state: State) =>
  !!state.account.details?.liveAgreement;

export const getAccount =
  (tokenOrId: string | undefined, onSuccess?: any): Thunk =>
  async dispatch => {
    dispatch(actions.getAccount());

    try {
      const { data } = await accountApiService.getAccount(tokenOrId);
      const entity = getDataItem<AccountDetails>(data);

      setVersionHttpHeader();

      await dispatch(checkMarketplaceAccess(entity.id));
      await dispatch(checkIsContentProvider(entity.id));

      dispatch(actions.getAccountSuccess(entity));

      if (onSuccess) {
        dispatch(onSuccess());
      }
    } catch (error) {
      dispatch(actions.getAccountError({ error }));
    }
  };

export const updateAccount =
  (payload: UpdateAccountPayload): Thunk =>
  async (dispatch, getState) => {
    dispatch(actions.updateAccount());

    try {
      const accountId = getAccountId(getState());
      const { data } = await accountApiService.updateAccount(
        accountId,
        payload,
      );
      const entity = getDataItem<AccountDetails>(data);

      dispatch(actions.updateAccountSuccess(entity));
    } catch (error) {
      dispatch(actions.updateAccountError({ error }));
    }
  };

export const toggleAccountLock =
  (payload: ToggleAccountLockPayload): Thunk =>
  async (dispatch, getState) => {
    dispatch(actions.toggleAccountLock());

    try {
      const accountId = getAccountId(getState());
      await accountApiService.toggleAccount(accountId, payload);

      dispatch(actions.toggleAccountLockSuccess());

      dispatch(getAccount(accountId));
    } catch (error) {
      dispatch(actions.toggleAccountLockError({ error }));
    }
  };

export const getVersion = (): Thunk => async dispatch => {
  dispatch(actions.getVersion());

  try {
    const { data } = await accountApiService.getVersions();
    const entity = getDataItem(data);

    dispatch(actions.getVersionSuccess(entity));
  } catch (error) {
    dispatch(actions.getVersionError({ error }));
  }
};

export const getApiKeys = (): Thunk => async dispatch => {
  dispatch(actions.getApiKeys());

  try {
    const { data } = await accountApiService.getApiKeys();

    dispatch(actions.getApiKeysSuccess(data));
  } catch (error) {
    dispatch(actions.getApiKeysError({ error }));
  }
};

export const sendGoLiveRequest =
  (account: SendGoLiveRequestPayload): Thunk =>
  async (dispatch, getState) => {
    dispatch(actions.sendGoLiveRequest());

    try {
      const accountId = getAccountId(getState());
      const { data } = await accountApiService.sendGoLiveRequest(
        accountId,
        account,
      );
      const entity = getDataItem<AccountDetails>(data);

      dispatch(actions.sendGoLiveRequestSuccess(entity));
    } catch (error) {
      dispatch(actions.sendGoLiveRequestError({ error }));
    }
  };

export const updateAccountSecurity =
  (payload: UpdateAccountSecurityPayload): Thunk =>
  async (dispatch, getState) => {
    dispatch(actions.updateAccountSecurity());

    try {
      const accountId = getAccountId(getState());
      const { data } = await accountApiService.updateAccountSecurity(
        accountId,
        payload,
      );
      const entity = getDataItem(data);

      dispatch(actions.updateAccountSecuritySuccess(entity));
    } catch (error) {
      dispatch(actions.updateAccountSecurityError({ error }));
    }
  };

export const getInvoices = (): Thunk => async (dispatch, getState) => {
  dispatch(actions.getInvoices());

  try {
    const state = getState();
    const accountId = getAccountId(state);
    const liveAgreement = selectLiveAgreement(state);
    const { data } = await accountApiService.getAllInvoices(
      accountId,
      liveAgreement,
    );

    dispatch(actions.getInvoicesSuccess(data));
  } catch (error) {
    dispatch(actions.getInvoicesError({ error }));
  }
};

const setVersionHttpHeader = async () => {
  let latestVersion;

  try {
    const response = await accountApiService.getVersions();

    latestVersion = response.data?.items?.[0]?.id || api.DEFAULT_VERSION;
  } catch (error) {
    latestVersion = api.DEFAULT_VERSION;
  }

  http.setApiVersion(latestVersion);
};

export const updateApiVersion =
  (version: string): Thunk =>
  async (dispatch, getState) => {
    dispatch(actions.updateApiVersion());

    try {
      const accountId = getAccountId(getState());
      const { data } = await accountApiService.updateApiVersion(
        accountId,
        version,
      );
      const entity = getDataItem<AccountDetails>(data);

      dispatch(actions.updateApiVersionSuccess(entity));
    } catch (error) {
      dispatch(actions.updateApiVersionError());
    }
  };

export const getLastInvoice = (): Thunk => async (dispatch, getState) => {
  dispatch(actions.getLastInvoice());

  try {
    const accountId = getAccountId(getState());
    const { data } = await accountApiService.getLastInvoice(accountId);
    const entity = getDataItem<Invoice>(data);

    dispatch(actions.getLastInvoiceSuccess(entity));
  } catch (error) {
    dispatch(actions.getLastInvoiceError({ error }));
  }
};

export const updateBillingConfigurations =
  (billingConfigurations: BillingConfigurations): Thunk =>
  async (dispatch, getState) => {
    dispatch(actions.updateBillingConfigurations());

    try {
      const accountId = getAccountId(getState());
      const { data } = await accountApiService.updateBillingConfigurations(
        accountId,
        billingConfigurations,
      );
      const entity = getDataItem<BillingConfigurations>(data);

      dispatch(actions.updateBillingConfigurationsSuccess(entity));
    } catch (error) {
      dispatch(actions.updateBillingConfigurationsError({ error }));
    }
  };

export const getPricingTemplate = (): Thunk => async dispatch => {
  dispatch(actions.getPricingTemplate());

  try {
    const { data } = await accountApiService.getPricingTemplate();
    const entity = getDataItem<PricingTemplate>(data);

    dispatch(actions.getPricingTemplateSuccess(entity));
  } catch (error) {
    dispatch(actions.getPricingTemplateError({ error }));
  }
};

export const getVirtualCards = (): Thunk => async dispatch => {
  dispatch(actions.getVirtualCards());

  try {
    const allCards = (await accountApiService.getVirtualCards()).data.items;

    const allCardsDetails: VirtualCard[] = await Promise.all(
      allCards.map(async (card: VirtualCard) => {
        const { data } = await accountApiService.getVirtualCard(card.id);
        const entity = getDataItem<VirtualCard>(data);

        return entity;
      }),
    );

    dispatch(actions.getVirtualCardsSuccess(allCardsDetails));
  } catch (error) {
    dispatch(actions.getVirtualCardsError({ error }));
  }
};

export const addVirtualCard =
  (payload: AddVirtualCardPayload): Thunk =>
  async dispatch => {
    dispatch(actions.addVirtualCard());

    try {
      const { data } = await accountApiService.addVirtualCard(payload);
      const entity = getDataItem<VirtualCard[]>(data);

      dispatch(actions.addVirtualCardSuccess(entity));
      dispatch(getVirtualCards());
    } catch (error) {
      dispatch(actions.addVirtualCardError({ error }));
    }
  };

export const checkMarketplaceAccess =
  (payload: string): Thunk =>
  async dispatch => {
    dispatch(actions.checkMarketplaceAccess());

    try {
      const response = await accountApiService.checkMarketplaceAccess(payload);
      const { status, data } = response;

      const { countryCodes, suppliers } = data.items?.[0] || {
        countryCodes: [],
        suppliers: [],
      };

      dispatch(
        actions.checkMarketplaceAccessSuccess({
          hasMarketplaceAccess: status >= 200 && status < 300,
          countryCodes,
          suppliers,
        }),
      );
    } catch (error) {
      dispatch(actions.checkMarketplaceAccessError({ error }));
    }
  };

export const checkIsContentProvider =
  (payload: string): Thunk =>
  async dispatch => {
    dispatch(actions.checkIsContentProvider());

    try {
      const { status, data } = await accountApiService.checkIsContentProvider(
        payload,
      );

      dispatch(
        actions.checkIsContentProviderSuccess(status === 200 && data?.items[0]),
      );
    } catch (error) {
      dispatch(actions.checkIsContentProviderError({ error }));
    }
  };

export const updateImages =
  (images: AccountImages): Thunk =>
  async (dispatch, getState) => {
    dispatch(actions.updateImages());

    try {
      const accountId = getAccountId(getState());
      const { data } = await accountApiService.updateImages(accountId, images);
      const entity = getDataItem<AccountDetails>(data);

      dispatch(actions.updateImagesSuccess(entity));
    } catch (error) {
      dispatch(actions.updateImagesError({ error }));
    }
  };

export const getProfile = (): Thunk => async (dispatch, getState) => {
  dispatch(actions.getProfile());

  try {
    const accountId = getAccountId(getState());
    const { data } = await accountApiService.getProfile(accountId);
    const entity = getDataItem<AccountProfile>(data);

    dispatch(actions.getProfileSuccess(entity));
  } catch (error) {
    dispatch(actions.getProfileError({ error }));
  }
};

export const getProfileStatus =
  (code: AccountNotification): Thunk =>
  async dispatch => {
    dispatch(statusActions.status({ code }));
  };

export const updateProfile =
  (profile: AccountProfile, { isLastStep } = { isLastStep: false }): Thunk =>
  async (dispatch, getState) => {
    dispatch(actions.updateProfile());

    try {
      const accountId = getAccountId(getState());
      const { data } = await accountApiService.updateProfile(
        accountId,
        profile,
      );
      const entity = getDataItem<AccountProfile>(data);

      dispatch(actions.updateProfileSuccess(entity));

      if (isLastStep)
        dispatch(getProfileStatus(AccountNotification.UPDATE_PROFILE));
    } catch (error) {
      dispatch(actions.updateProfileError({ error }));
    }
  };

export const { clear } = actions;
