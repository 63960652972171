import React from 'react';

import { AccountDetails } from '../store/account/account-model';
import amexIcon from '../assets/amex_icon.svg';
import mastercardIcon from '../assets/mastercard_icon.svg';
import visaIcon from '../assets/visa_icon.svg';
import CombinedSchemes from '../components/combined-schemes';

export const icons: any = {
  visa: visaIcon,
  mastercard: mastercardIcon,
  amex: amexIcon,
};

const schemes: Scheme[] = ['visa', 'mastercard', 'amex'];

export function isScheme(scheme: string): scheme is Scheme {
  return schemes.includes(scheme as Scheme);
}

export type Scheme = 'mastercard' | 'visa' | 'amex';
export type CombinedScheme = 'visa&mastercard';

export type Schemes = Scheme | CombinedScheme;

export default schemes;

const buildSchemeOptions = (options: Scheme[]) =>
  options.map(scheme => ({
    label: <img alt={scheme} src={icons[scheme]} role="presentation" />,
    value: scheme,
  }));

export const schemeOptions = buildSchemeOptions(['visa', 'mastercard', 'amex']);

export const filterSchemeOptions = buildSchemeOptions(['visa', 'mastercard']);

type SchemesObject = {
  label: React.ReactElement;
  value: Schemes;
};

const parseUndefinedSchemes = (
  accountAchemes: Pick<AccountDetails, 'amex' | 'mastercard' | 'visa'>,
) => {
  const { amex, mastercard, visa } = accountAchemes;

  return {
    amex: amex ?? true,
    mastercard: mastercard ?? true,
    visa: visa ?? true,
  };
};

export function getAllSchemeOptions(
  accountDetails: AccountDetails,
): SchemesObject[] {
  const { visa, mastercard, amex } = parseUndefinedSchemes(accountDetails);
  const cardSchemes: SchemesObject[] = [];

  if (!visa && mastercard) {
    cardSchemes.push({
      label: (
        <img alt="mastercard" src={icons.mastercard} role="presentation" />
      ),
      value: 'mastercard',
    });
  }

  if (visa && !mastercard) {
    cardSchemes.push({
      label: <img alt="visa" src={icons.visa} role="presentation" />,
      value: 'visa',
    });
  }

  if (visa && mastercard) {
    cardSchemes.push({
      label: <CombinedSchemes icons={icons} />,
      value: 'visa&mastercard',
    });
  }

  if (amex) {
    cardSchemes.push({
      label: <img alt="amex" src={icons.amex} role="presentation" />,
      value: 'amex',
    });
  }

  return cardSchemes;
}

export function getEnabledSchemesForAccount(accountDetails: AccountDetails) {
  const schemesStatus: Record<string, boolean> = {};

  schemes.forEach(scheme => {
    // Enabled by default if not present
    if (scheme === 'mastercard' || scheme === 'visa') {
      schemesStatus[scheme] =
        !!accountDetails[scheme] || accountDetails[scheme] === undefined;
    } else {
      schemesStatus[scheme] = !!accountDetails[scheme];
    }
  });

  return schemesStatus;
}

const AMEX = 'XXXX XXXXXX XXXXX';
const STANDARD = 'XXXX XXXX XXXX XXXX';
export function formatCardNumber(cardNo: string, scheme: Scheme) {
  let result = scheme === 'amex' ? AMEX : STANDARD;
  for (let i = 0; i < cardNo.length; i++) {
    result = result.replace('X', cardNo[i]);
  }
  return result;
}
