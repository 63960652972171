import { Program } from '../programs/programs-reducer';
import { State } from '../create-store';

import LocationsApiService, {
  CreateLocationPayload,
  LocationsOptions,
  MapUniqueLocationsToProgramOptions,
  UpdateLocationPayload,
} from './locations-api-service';

import { actions } from './locations-reducer';

import { Location } from '../locations/locations-model';

import {
  LocationsFilter,
  UniqueLocationsFilters,
} from '../filters/filters-reducer';
import { Thunk } from '../types';
import locationMatchesFilter from '../utils/location-matches-filter';

const getFilters = (state: State) => state.filters.locations;

const locationsService = new LocationsApiService();

export const { clear } = actions;

export const getLocations =
  (programId: string, last?: any): Thunk =>
  async (dispatch, getState) => {
    const isLoadMore = !!last;

    dispatch(
      isLoadMore
        ? actions.getMoreLocations(programId)
        : actions.getLocations(programId),
    );

    try {
      const filters = (getState() as State).filters.locations || {};
      const response = await locationsService.getLocations({
        programId,
        filters,
        last,
      });
      const { items, last: newLast } = response.data;
      const payload = { programId, items, last: newLast };

      if (isLoadMore) {
        dispatch(actions.getMoreLocationsSuccess(payload));
      } else {
        dispatch(actions.getLocationsSuccess(payload));
      }
    } catch (error) {
      if (isLoadMore) {
        dispatch(actions.getMoreLocationsError({ programId, error }));
      } else {
        dispatch(actions.getLocationsError({ programId, error }));
      }
    }
  };

export const getAllLocations =
  (programs: Program[], filters?: LocationsFilter): Thunk =>
  async dispatch => {
    dispatch(actions.getAllLocations(programs));

    const payload = await Promise.all(
      programs.map(({ id: programId }) =>
        locationsService
          .getAllLocations({
            programId,
            filters,
          })
          .then(items => ({ programId, items }))
          .catch(error => ({ programId, error })),
      ),
    );

    dispatch(actions.getAllLocationsDone(payload));
  };

export const getAllLinkedLocations =
  (programId: string, brandId: string): Thunk =>
  async dispatch => {
    dispatch(actions.getAllLinkedLocations({ programId }));

    await locationsService
      .getAllLocations({ programId, filters: { brandId } })
      .then(items => {
        dispatch(actions.getAllLinkedLocationsSuccess({ programId, items }));
      })
      .catch(error => {
        dispatch(actions.getAllLinkedLocationsError({ programId, error }));
      });
  };

export const getExportableLocations =
  (options: LocationsOptions): Thunk =>
  async (dispatch, getState) => {
    dispatch(actions.getExportableLocations());

    try {
      const filters = getFilters(getState());
      const response = await locationsService.getAllLocations(options);

      const locations = response.filter((location: Location) =>
        locationMatchesFilter(location, filters),
      );
      dispatch(actions.getExportableLocationsSuccess({ locations }));
    } catch (error) {
      dispatch(actions.getExportableLocationsError({ error }));
    }
  };

export const getMoreLocations =
  (programId: string): Thunk =>
  async (dispatch, getState) => {
    const location = (getState() as State).locations.locations[programId];

    if (location) dispatch(getLocations(programId, location.last));
  };

export const getLocationsCount =
  (programId: string, locationFilters?: LocationsFilter): Thunk =>
  async dispatch => {
    dispatch(actions.getLocationsCount({ programId }));

    try {
      const count = await locationsService.getLocationsCount(
        programId,
        locationFilters,
      );
      dispatch(actions.getLocationsCountSuccess({ programId, count }));
    } catch (error) {
      dispatch(actions.getLocationsCountError({ programId }));
    }
  };

export const createLocation =
  (programId: string, location: CreateLocationPayload): Thunk =>
  async dispatch => {
    dispatch(actions.createLocation());

    try {
      await locationsService.createLocation(programId, location);
      dispatch(actions.createLocationSuccess());
      dispatch(getLocations(programId));
      dispatch(getLocationsCount(programId));
    } catch (error) {
      dispatch(actions.createLocationError({ error }));
    }
  };

export const deleteLocation =
  (programId: string, locationIds: string | string[]): Thunk =>
  async dispatch => {
    dispatch(actions.deleteLocation());

    const arrayLocationIds = Array.isArray(locationIds)
      ? locationIds
      : [locationIds];

    try {
      await Promise.all(
        arrayLocationIds.map(locationId =>
          locationsService.deleteLocation(locationId),
        ),
      );

      dispatch(actions.deleteLocationSuccess());
      dispatch(getLocations(programId));
      dispatch(getLocationsCount(programId));
    } catch (error) {
      dispatch(actions.deleteLocationError({ error }));
    }
  };

export const updateLocation =
  (
    programId: string,
    locationId: string,
    location: UpdateLocationPayload,
  ): Thunk =>
  async dispatch => {
    dispatch(actions.updateLocation());

    try {
      await locationsService.updateLocation(locationId, location);
      dispatch(actions.updateLocationSuccess());
      dispatch(getLocations(programId));
    } catch (error) {
      dispatch(actions.updateLocationError({ error }));
    }
  };

export const getUniqueLocations =
  (uniqueBrandId: string): Thunk =>
  async dispatch => {
    dispatch(actions.getUniqueLocations({ uniqueBrandId }));
    try {
      const { data } = await locationsService.getUniqueLocations({
        uniqueBrandId,
      });
      const { items, last } = data;

      dispatch(
        actions.getUniqueLocationsSuccess({
          uniqueBrandId,
          items,
          last,
        }),
      );
    } catch (error) {
      dispatch(actions.getUniqueLocationsError({ uniqueBrandId, error }));
    }
  };

export const getAllUniqueLocations =
  (uniqueBrandId: string, filters?: UniqueLocationsFilters): Thunk =>
  async dispatch => {
    dispatch(actions.getAllUniqueLocations({ uniqueBrandId }));

    await locationsService
      .getAllUniqueLocations({ uniqueBrandId, ...(filters && { filters }) })
      .then(items => {
        dispatch(
          actions.getAllUniqueLocationsSuccess({ uniqueBrandId, items }),
        );
      })
      .catch(error => {
        dispatch(actions.getAllUniqueLocationsError({ uniqueBrandId, error }));
      });
  };

export const mapUniqueLocationsToProgram =
  ({
    programId,
    uniqueBrandId,
    brandId,
    uniqueLocationIds,
  }: MapUniqueLocationsToProgramOptions): Thunk =>
  async dispatch => {
    dispatch(actions.mapUniqueLocationsToProgram());

    await locationsService
      .mapUniqueLocationsToProgram({
        programId,
        uniqueBrandId,
        brandId,
        uniqueLocationIds,
      })
      .then(() => {
        dispatch(
          actions.mapUniqueLocationsToProgramSuccess({
            selectedLocationsToMapCount: uniqueLocationIds.length,
          }),
        );
      })
      .catch(error => {
        dispatch(actions.mapUniqueLocationsToProgramError({ error }));
      });
  };

export const resetMapUniqueLocationsToProgramStatus = (): Thunk => dispatch => {
  dispatch(actions.resetMapUniqueLocationsToProgram());
};
