import pkgJson from '../package.json';

function getRequiredEnv(key: string) {
  const value = process.env[key];
  if (!value) {
    throw new Error(`Required environment variable ${key} not found`);
  }

  return value;
}

export const e2e = {
  USER: JSON.parse(getRequiredEnv('REACT_APP_E2E_USER')),
};

export const app = {
  NAME: 'fidel-dashboard',
  VERSION: pkgJson.version,
  ENV: getRequiredEnv('REACT_APP_ENV'),
  tests: {
    e2e,
  },
};

export const api = {
  BASE_URL: getRequiredEnv('REACT_APP_FIDEL_API_BASE_URL'),
  KEY: getRequiredEnv('REACT_APP_FIDEL_API_KEY'),
  DEFAULT_VERSION: getRequiredEnv('REACT_APP_FIDEL_API_DEFAULT_VERSION'),
  LIMIT: 100,
  FILTERED_ITEMS_LIMIT: 50,
  MAX_BRANDS_COUNT: 10000,
  MAX_OFFERS_COUNT: 10000,
  MAX_TRANSACTION_COUNT: 10000,
  MAX_CARDS_COUNT: 10000,
  MAX_LOCATIONS_COUNT: 10000,
  MAX_ACCOUNTS_COUNT: 10000,
  MAX_WEBHOOKS_COUNT: 10000,
};

export const namespaces = {
  ACCOUNT: 'Fidel-Account-Dashboard',
  USER: 'Fidel-User-Merchant',
};

export const resources = {
  BASE_URL: getRequiredEnv('REACT_APP_FIDEL_API_RESOURCES_BASE_URL'),
};

export const sdk = {
  ENV: ['dev', 'prod'].includes(app.ENV) ? app.ENV : 'qa',
  URL: 'https://resources.fidel.uk/sdk/js/v3/fidel.js',
};

export const cognito = {
  POOL_ID: getRequiredEnv('REACT_APP_COGNITO_POOL_ID'),
  CLIENT_ID: getRequiredEnv('REACT_APP_COGNITO_CLIENT_ID'),
  DOMAIN_URL: getRequiredEnv('REACT_APP_COGNITO_DOMAIN_URL'),
};

export const reCaptcha = {
  SITE_KEY: getRequiredEnv('REACT_APP_RECAPTCHA_SITE_KEY'),
};
