import React, { useState, useEffect } from 'react';

import { Trans } from 'react-i18next';
import { Button } from 'antd';

import { selectPaymentFailedInvoices } from '../../store/account/account-selectors';
import { StyledAlert } from './styled';
import { roleCheck } from '../role-check';
import useRedirect from '../../hooks/use-redirect';
import { useAppSelector } from '../../store/hooks';

type AlertMessage =
  | 'failedPayment'
  | 'reenterPayment'
  | 'cardToExpire'
  | 'maintenance';

interface AlertProps {
  maintenance?: {
    date: string;
    from: string;
    to: string;
  };
}

const Alert = ({ maintenance }: AlertProps) => {
  const redirect = useRedirect();

  const [message, setMessage] = useState<AlertMessage | null>(null);
  const failedInvoices = useAppSelector(selectPaymentFailedInvoices);

  useEffect(() => {
    setMessage(null);
    if ((failedInvoices || []).length > 0) {
      setMessage('failedPayment');
    }

    if (maintenance) {
      setMessage('maintenance');
    }
  }, [failedInvoices, maintenance]);

  if (!message) return null;

  const { date, from, to } = maintenance || {};

  return (
    <StyledAlert
      type="info"
      showIcon={false}
      banner
      message={
        <Trans
          i18nKey={`alert.${message}`}
          values={{
            date,
            from,
            to,
          }}
          components={{
            'cta-link': (
              <Button
                data-testid="alert-cta"
                type="link"
                onClick={() => {
                  redirect('/account/billing');
                }}
              />
            ),
          }}
        />
      }
    />
  );
};

export default roleCheck(
  { disabledForModerator: true, requiresAccountAdmin: true },
  Alert,
);
