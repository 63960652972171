import React, { useState } from 'react';
import { Button, Drawer, Row, Input, Typography } from 'antd';
import { useTranslation } from 'react-i18next';
import { useForm } from 'react-hook-form';

import { reviewAccount } from '../../../store/moderator/moderator-actions';
import drawerTransition, {
  DrawerVisibleProps,
} from '../../../components/drawer-transition';
import Feedback from '../../../components/feedback';
import { GreenButton, SecondaryLinkButton } from '../../../components/buttons';
import { ReactComponent as DocumentIcon } from '../../../assets/document.svg';
import { AccountTableDocRow } from '../../../components/tables/styled/helpers';
import {
  DownloadButton,
  FieldsGroup,
  FieldsGroupContainer,
  Label,
  ReviewField,
  Table,
  StyledModal,
} from '../styled/review-account';
import { useSetStatus } from '../../../hooks/use-status';
import { DOUBLE_MINUS } from '../../../utils/special-character';
import downloadDocument from '../../../utils/download-document';
import ConditionalTooltip from '../../../components/conditional-tooltip';
import Form from '../../../components/form';
import { useAppDispatch } from '../../../store/hooks';

type Field = [string, string];

interface ReviewAccountProps extends DrawerVisibleProps {
  account: any;
  onClose: () => any;
}

const ReviewAccount = ({ account, onClose, visible }: ReviewAccountProps) => {
  const { t } = useTranslation();
  const { t: tModeratorReview } = useTranslation('common', {
    keyPrefix: 'moderator.review.label',
  });
  const dispatch = useAppDispatch();
  const [review, setReview] = useState<{ approved: boolean } | undefined>(
    undefined,
  );
  const { setErrorMessage } = useSetStatus();

  const {
    control,
    formState: { errors },
    handleSubmit,
  } = useForm({ mode: 'onBlur' });

  const {
    id,
    liveRequest,
    name,
    countryCode,
    address,
    city,
    postcode,
    vatNumber,
    website,
    appName,
    monthlyActiveUsersRange,
    monthlyTransactionsRange,
    cardsOnFileRange,
    description,
    userId,
    termsAndConditionsDocumentUrl,
    privacyPolicyDocumentUrl,
    faqDocumentUrl,
    pciDssAoCDocumentUrl,
    accountDataSet,
  } = account || {};

  const { nameFirst, nameLast, email } = userId || {};

  // TODO: Make the second condition simply !accountDataSet
  // after the BE is ready and accountDataSet is set to false by default
  const approveButtonDisabled = accountDataSet === false;

  const legalDocuments = [
    {
      documentUrl: termsAndConditionsDocumentUrl,
      key: tModeratorReview('termsOfService'),
    },
    {
      documentUrl: privacyPolicyDocumentUrl,
      key: tModeratorReview('privacyPolicy'),
    },
    { documentUrl: faqDocumentUrl, key: tModeratorReview('faq') },
    { documentUrl: pciDssAoCDocumentUrl, key: tModeratorReview('pciDssAoc') },
  ].filter(({ documentUrl }) => !!documentUrl);

  const companyFields: Field[] = [
    [tModeratorReview('companyName'), name],
    // TODO Fix 'any' below
    [
      tModeratorReview('country'),
      countryCode ? t(`countries.${countryCode}` as any) : null,
    ],
    [tModeratorReview('fullAddress'), `${address}, ${city}, ${postcode}`],
    [tModeratorReview('vatNumber'), vatNumber],
    [tModeratorReview('website'), website],
  ];

  const businessFields: Field[] = [
    [tModeratorReview('appName'), appName],
    [tModeratorReview('monthlyActiveUsersRange'), monthlyActiveUsersRange],
    [tModeratorReview('monthlyTransactionsRange'), monthlyTransactionsRange],
    [tModeratorReview('cardsOnFileRange'), cardsOnFileRange],
    [tModeratorReview('description'), description],
  ];

  const contactFields: Field[] = [
    [tModeratorReview('name'), `${nameFirst} ${nameLast}`],
    [tModeratorReview('email'), email],
  ];

  function fieldElements(fields: Field[]) {
    return fields.map(([label, value]) => (
      <ReviewField key={label}>
        <Label>{label}</Label>
        <p>{value || DOUBLE_MINUS}</p>
      </ReviewField>
    ));
  }

  const certificateTable = legalDocuments.map(
    ({ documentUrl, key: documentName }) => ({
      contents: [
        <AccountTableDocRow>
          <DocumentIcon />
          <Typography.Paragraph>{documentName}</Typography.Paragraph>
        </AccountTableDocRow>,
        <DownloadButton
          onClick={() =>
            downloadDocument(documentUrl, () =>
              setErrorMessage(
                t('moderator.review.downloadError', { documentName }),
              ),
            )
          }
        >
          {t('export.download')}
        </DownloadButton>,
      ],
    }),
  );

  function onReview({ message }: any) {
    dispatch(
      reviewAccount({
        accountId: id,
        liveRequestId: liveRequest?.id,
        approved: !!review?.approved,
        message,
      }),
    );
    setReview(undefined);
    onClose();
  }

  return (
    <Drawer
      visible={visible}
      closable={false}
      destroyOnClose
      width={440}
      onClose={onClose}
      title={t('moderator.review.title')}
      footer={
        <Row style={{ flex: '1 0 auto' }}>
          <SecondaryLinkButton onClick={onClose} size="small">
            {t('confirm.cancel')}
          </SecondaryLinkButton>
          <div style={{ flex: '1 0 auto', textAlign: 'right' }}>
            <Button
              type="primary"
              danger
              style={{ marginRight: '10px' }}
              onClick={() => setReview({ approved: false })}
              size="small"
            >
              {t('moderator.review.reject')}
            </Button>
            <ConditionalTooltip
              condition={approveButtonDisabled}
              placement="topLeft"
              title={t('moderator.review.needsAccountDataSet')}
            >
              <GreenButton
                data-testid="accept"
                size="small"
                onClick={() => setReview({ approved: true })}
                disabled={approveButtonDisabled}
              >
                {t('moderator.review.approve')}
              </GreenButton>
            </ConditionalTooltip>
          </div>
        </Row>
      }
    >
      <FieldsGroupContainer>
        {[companyFields, businessFields, contactFields].map((fields, idx) => (
          <FieldsGroup key={idx}>{fieldElements(fields)}</FieldsGroup>
        ))}
      </FieldsGroupContainer>
      {legalDocuments.length > 0 && (
        <Table
          columns={[
            { heading: t('moderator.review.documents'), size: 0.7 },
            { heading: '', size: 0.2, align: 'right' },
          ]}
          minWidth={330}
          rows={certificateTable}
        />
      )}
      <Feedback right={460} />
      {!!review && (
        <StyledModal
          visible
          title={t(
            `moderator.review.modal.${
              review.approved ? 'approve' : 'reject'
            }Title`,
          )}
          onCancel={() => setReview(undefined)}
          okText={t(
            `moderator.review.modal.${review?.approved ? 'approve' : 'reject'}`,
          )}
          okButtonProps={{
            danger: !review?.approved,
          }}
          onOk={handleSubmit(onReview)}
        >
          <Form layout="vertical">
            <Form.ItemController
              label={t('moderator.review.modal.message')}
              errors={errors}
              controller={{
                name: 'message',
                render: ({ field }) => (
                  <Input.TextArea
                    {...field}
                    placeholder={t('moderator.review.modal.messagePlaceholder')}
                    autoSize={{ minRows: 5 }}
                    style={{ resize: 'none' }}
                  />
                ),
                control,
                rules: { required: true },
              }}
            />
          </Form>
        </StyledModal>
      )}
    </Drawer>
  );
};

export default drawerTransition(ReviewAccount);
