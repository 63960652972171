import React from 'react';

import { shallowEqual } from 'react-redux';
import { useTranslation } from 'react-i18next';

import {
  AccountDetails,
  Invoice,
} from '../../../../store/account/account-model';

import { Subsection, SubsectionSubtitle, SubsectionTitle } from '../../styled';
import memoNoProps from '../../../../utils/memo-no-props';
import { isVatCountry } from '../../../../utils/countries';
import { toDecimal } from '../../../../utils/transform';
import { useAppSelector } from '../../../../store/hooks';
import { DOUBLE_MINUS } from '../../../../utils/special-character';

export function getAmount(
  details: AccountDetails | undefined,
  invoice: Invoice,
) {
  const { countryCode, vatNumber } = details || {};
  const { amount, amountVAT } = invoice;

  return toDecimal(isVatCountry(countryCode, vatNumber) ? amountVAT : amount);
}

const Billing = () => {
  const { t } = useTranslation();

  const { accountDetails } = useAppSelector(
    state => ({
      accountDetails: state.account.details,
      loading: state.account.loading,
    }),
    shallowEqual,
  );

  const { liveActive, vatNumber, poNumber, legalName } = accountDetails || {};

  return (
    <>
      <Subsection>
        <SubsectionTitle>
          <h2>{t('account.billingPeriod.title')}</h2>
          <p>{t('account.billingPeriod.description')}</p>
        </SubsectionTitle>
      </Subsection>
      {liveActive && (
        <Subsection
          style={{ gap: '1rem', display: 'flex', flexDirection: 'column' }}
        >
          <SubsectionTitle>
            <h2>{t('account.billingInfo.title')}</h2>
          </SubsectionTitle>
          <p>{t('account.billingInfo.description')}</p>
          <div>
            <h4>{t('account.billingInfo.poNumber')}</h4>
            <SubsectionSubtitle>{poNumber || DOUBLE_MINUS}</SubsectionSubtitle>
          </div>
          <div>
            <h4>{t('account.billingInfo.legalName')}</h4>
            <SubsectionSubtitle>{legalName || DOUBLE_MINUS}</SubsectionSubtitle>
          </div>
          <div>
            <h4>{t('account.billingInfo.vatNumber')}</h4>
            <SubsectionSubtitle>{vatNumber || DOUBLE_MINUS}</SubsectionSubtitle>
          </div>
        </Subsection>
      )}
    </>
  );
};

export default memoNoProps(Billing);
