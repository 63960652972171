import { LocationsFilter } from '../filters/filters-reducer';
import { Location } from '../locations/locations-model';

export default function locationMatchesFilter(
  location: Location,
  filters: LocationsFilter = {},
): boolean {
  if (!filters) return true;

  if (filters.id && location.id) return filters.id === location.id;

  if (filters.brandId && location.brandId)
    return filters.brandId === location.brandId.id;

  if (filters.postcode && location.postcode)
    return location.postcode.includes(filters.postcode);

  const amexStatusMatches =
    location.amex && location.amex.status === filters.status;
  const visaStatusMatches =
    location.visa && location.visa.status === filters.status;
  const mastercardStatusMatches =
    location.mastercard && location.mastercard.status === filters.status;

  return !(
    filters.status &&
    (amexStatusMatches || visaStatusMatches || mastercardStatusMatches)
  );
}
