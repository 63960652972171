import { AccountStatusTypesEnum } from '@fidel.uk/types/lib/account';
import { SupportedCountriesType } from '../../utils/countries';
import { ProgramType } from '../programs/programs-reducer';

interface InvoiceNextPeriod {
  brackets: [number, number][];
  minTransactions: number;
}

export interface Invoice {
  amount: number;
  amountVAT: number;
  charged: boolean;
  chargeDate?: string;
  created: string;
  currency: string;
  id: string;
  paymentFailed?: boolean;
  nextPeriod?: InvoiceNextPeriod;
  invoiceNumber?: number;
  issuedDate: string;
  status?: 'open' | 'closed' | 'completed';
  pdfUrl?: string;
  csvUrl?: string;
}

export interface ApiKeys {
  secret: {
    live: string;
    test: string;
  };
  public: {
    live: string;
    test: string;
  };
}

export interface Config {
  security: {
    removeProperties: string[];
    certificatePinning: boolean;
  };
}

export function transformToApiKeys(
  apiKeys: Record<string, Array<Record<string, string>>>,
): ApiKeys {
  const keys: Record<string, string> = {};
  apiKeys.items.forEach(({ type, id }) => {
    keys[type] = `${type}_${id}`;
  });

  return {
    secret: { live: keys.sk_live, test: keys.sk_test },
    public: { live: keys.pk_live, test: keys.pk_test },
  };
}

type Product = 'transactionStream' | 'transactionDistroOnly';

export type Products = Partial<Record<Product, boolean>>;

export type VisaConfig = {
  vopCommunityCode?: string;
  vopPromoCode?: string;
};

export interface AccountDetails {
  accountDataSet?: boolean;
  address: string;
  amex?: boolean;
  apiVersion: string;
  billingDataSet?: boolean;
  city: string;
  companyNumber?: string;
  config?: Config;
  countryCode: string;
  croppedImagePath?: string;
  description?: string;
  id: string;
  imagePath?: string;
  lastActive: string;
  legalName: string;
  liveActive: boolean;
  liveAgreement: boolean;
  liveRequest?: {
    approved?: boolean;
    reviewingUser?: {
      email: string;
      userId: string;
    };
    reviewMessage?: string;
    reviewTimestamp?: string;
  };
  mastercard?: boolean;
  name: string;
  poNumber?: string;
  postCode: string;
  products?: Products;
  programType: ProgramType;
  review: boolean;
  subscriptionActive: boolean;
  status?: AccountStatusTypesEnum;
  termsAndConditionsDocumentUrl?: string;
  territories?: SupportedCountriesType[];
  title: string;
  userId: {
    email: string;
    id: string;
    nameFirst: string;
    nameLast: string;
  };
  vatNumber?: string;
  visa?: boolean;
  visaConfig?: VisaConfig;
}

interface Period {
  invoiceNumber: number;
}

export interface SelectTransactionPeriod extends Period {
  minimumFee: number;
  minimumTransactions: number;
  disableProration: boolean;
  brackets: number[][];
}

export interface TransactionStreamPeriod extends Period {
  minimumFee: number;
  disableProration: boolean;
  cardPricing: {
    cardVerifiedFee: {
      visa: number;
      mastercard: number;
    };
    cardVerificationFee: number;
  };
}

export interface AdjustmentPeriod extends Period {
  amount: number;
}

export type ProductPeriod =
  | SelectTransactionPeriod
  | TransactionStreamPeriod
  | AdjustmentPeriod;

export type Currency = 'GBP' | 'CAD' | 'USD' | 'EUR' | 'AED';

export interface BillingConfigurations {
  commencementDate?: string;
  contractUrl?: string;
  currency?: Currency;
  invoiceDay?: number;
  invoicesDisabled?: boolean;
  invoiceEmails?: string[];
  onboardingFee?: number;
  transactionPricing?: {
    periods: SelectTransactionPeriod[];
  };
  transactionStreamPricing?: {
    periods: TransactionStreamPeriod[];
  };
  adjustment?: {
    periods: AdjustmentPeriod[];
  };
  subscriptionActive?: boolean;
}

export interface VirtualCard {
  accountId: string;
  created: string;
  expMonth: number;
  expYear: number;
  cvc: number;
  id: string;
  lastNumbers: string;
  fullNumber: string;
  cardholder: {
    name: string;
    email: string;
  };
  live: boolean;
  scheme: 'visa' | 'mastercard' | 'amex';
  updated?: string;
}

interface BracketsType {
  brackets: [number, number][];
}

export interface PricingTemplate {
  transactionPricing: {
    [index: string]: BracketsType;
    GBP: BracketsType;
    USD: BracketsType;
    CAN: BracketsType;
  };
}

export interface NewAccount {
  nameFirst: string;
  nameLast: string;
  companyName: string;
  email: string;
  password: string;
}

export interface AccountImages {
  croppedImage: string | undefined;
  image: string | undefined;
}

export interface AccountProfile {
  pitch?: string;
  websiteUrl?: string;
  androidAppUrl?: string;
  iosAppUrl?: string;
  audience?: string;
  audienceSize?: string;
  size?: string;
  growthForecast?: string;
  accountId?: string;
  created?: string;
  updated?: string;
}

export enum AccountNotification {
  UPDATE_PROFILE = 'updateProfileNotification',
}

export interface ContentProviderInfo {
  accountId: AccountDetails['id'];
  identifier: string;
  name: string;
  requiredFields: string[];
  minOfferExpireDays: number;
}
